import React from 'react';

import './phoneInput.scss';
import { useController } from 'react-hook-form';
import Tooltip from '../Tooltip/Tooltip';

import PhoneInputComponent from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const PhoneInput = (props) => {
  const { field } = useController(props);
  return (
    <div className={'phone__input'}>
      <div className={'label-wrap'}>
        {props.label && (
          <label htmlFor={props.id} className={props.errors ? 'label error' : 'label'}>
            {props.label}
            {props.rules && props.rules.required ? <span className={'required'}>*</span> : ''}
          </label>
        )}
        {props.tooltip && props.tooltip.show ? <Tooltip params={props.tooltip} /> : ''}
      </div>
      <div className={props.errors ? 'input-wrap error' : 'input-wrap'}>
        <PhoneInputComponent
          {...field}
          excludeCountries={props.exclude}
          country={props.country}
          containerClass={'phoneInputContainer'}
          inputClass={'phoneInput'}
          buttonClass={'phoneButton'}
          dropdownClass={'phoneDropdown'}
          enableSearch
        />
        {props.errors && <p className={'errorName'}>{props.errors.message}</p>}
      </div>
    </div>
  );
};

export default PhoneInput;
