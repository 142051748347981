import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import './resetPassword.scss';
import { Link, useNavigate } from 'react-router-dom';
import PasswordInput from '../../../components/ui/PasswordInput';
import LangSwitcher from '../../../components/ui/LangSwitcher';
import Button from '../../../components/ui/Button';
import { Key } from 'phosphor-react';
import { Col, Container, Row } from 'react-bootstrap';
import { notify } from '../../../utils/notify';
import Preloader from '../../../components/ui/Preloader/Preloader';
import { newPassword } from '../../../api/auth';
import { useWindowWidth } from '@react-hook/window-size';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { PASSWORD_INPUT_VALIDATION } from '../../../constants/validators';
import { useSelector } from "react-redux";
import { getCompanyName, getLogo, getProperLogo } from "../../../utils/selectors";
import LogoDefault from "../../../assets/img/logo_default.svg";
import * as LOGOS from '../../../assets/img/'
interface ISendData {
  email: string,
  code: string,
  password: string,
  password_confirmation: string,
}

const ResetPassword = () => {
  const intl: IntlShape = useIntl();
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
  });

  const url: string[] = window.location.href.split('/');
  const email: string = url[5];
  const comfirmCode: string = url[6];
  const navigate = useNavigate();
  const width: number = useWindowWidth();
  const companyName = useSelector(getCompanyName);
  const logo = useSelector(getProperLogo)
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const new_password = watch('password');

  const changingTabTitle = () => {
    document.title = intl.formatMessage({
      id: 'auth.resetPassword.title',
      defaultMessage: 'Reset password',
    });
  };

  const onSubmit = (data): void => {
    setIsLoading(true);
    const sendData: ISendData = {
      email: email,
      code: comfirmCode,
      password: data.password,
      password_confirmation: data.password_confirmation,
    };

    newPassword(sendData)
      .then((res): void => {
        if (res) {
          notify({
            type: 'success',
            message: res.data.message,
            timeOut: 3000,
          });
          setTimeout((): void => {
            navigate('/');
          }, 1000);
        }
      })
      .catch((error): void => {
        if (error && error.response.data !== null) {
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      })
      .finally((): void => {
        setIsLoading(false)
      });
  };

  useEffect((): void => {
    changingTabTitle();
  }, []);

  return (
    <div className={'reset__password'}>
      <div className={'page'}>
        <div className={'login auth'}>
          <div className={'login__col auth__descr'}>
            <div className={'auth__descr-img'}>
              <Key size={170} />
            </div>
            <div className={'auth__descr-text'}>
              <p>
                <FormattedMessage
                  id="auth.resetPassword.sideBar.title"
                  defaultMessage="Do not have an account yet?"
                />
              </p>
              <Link to="/sign-up" className={'link-text'}>
                <FormattedMessage
                  id="auth.resetPassword.sideBar.btnText"
                  defaultMessage="Sign up here"
                />
              </Link>
            </div>
          </div>
          <div className={'login__col'}>
            <div className={'login__header'}>
              <div className={'login__title'}>
                <FormattedMessage id="auth.resetPassword.title" defaultMessage="Reset password" />
              </div>
              <LangSwitcher />
            </div>
            <div className={'login__content'}>
              <form className={'form'} onSubmit={handleSubmit(onSubmit)}>
                {isLoading ? <Preloader /> : null}
                <Container className={isLoading ? 'p-0 blur' : 'p-0'}>
                  <Row className={'form__line'}>
                    <Col md={12}>
                      <PasswordInput
                        name="password"
                        type="password"
                        label={
                          <FormattedMessage
                            id="changePassword.form.field.label.newPassword"
                            defaultMessage="New password"
                          />
                        }
                        control={control}
                        rules={{
                          required: (
                            <FormattedMessage
                              id="form.error.requiredFieldnoName"
                              defaultMessage="Field is required"
                            />
                          ),
                          validate: PASSWORD_INPUT_VALIDATION,
                        }}
                        errors={errors.password}
                      />
                    </Col>
                    <Col md={12}>
                      <PasswordInput
                        name="password_confirmation"
                        type="password"
                        label={
                          <FormattedMessage
                            id="changePassword.form.field.label.confirmPassword"
                            defaultMessage="Confirm password"
                          />
                        }
                        control={control}
                        rules={{
                          required: (
                            <FormattedMessage
                              id="form.error.requiredFieldnoName"
                              defaultMessage="Field is required"
                            />
                          ),
                          validate: {
                            ...PASSWORD_INPUT_VALIDATION,
                            compare: (value) =>
                              value === new_password || (
                                <FormattedMessage
                                  id="form.error.passwordMessage_8"
                                  defaultMessage="The password confirmation does not match."
                                />
                              ),
                          },
                        }}
                        errors={errors.password_confirmation}
                      />
                    </Col>
                  </Row>
                  {width <= 580 ? (
                    <Row>
                      <Col>
                        <div className={'auth__descr-text'}>
                          <p>
                            <FormattedMessage
                              id="auth.resetPassword.sideBar.title"
                              defaultMessage="Do not have an account yet?"
                            />
                          </p>
                          <Link to="/sign-up" className={'link-text'}>
                            <FormattedMessage
                              id="auth.resetPassword.sideBar.btnText"
                              defaultMessage="Sign up here"
                            />
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    ''
                  )}
                  <div className={'button-wrap'}>
                    <Button
                      type="submit"
                      buttonText={
                        <FormattedMessage
                          id="auth.resetPassword.btnText"
                          defaultMessage="Reset password"
                        />
                      }
                      size="big"
                      disabled={isLoading}
                    />
                  </div>
                </Container>
              </form>
            </div>
          </div>
          <div className={'logo'}>
            <img src={logo} alt="logo" />
          </div>
        </div>
      </div>
      <div className={'copyright'}>
        © {new Date().getFullYear()} {companyName}, Inc. All rights Reserved
      </div>
    </div>
  );
};

export default ResetPassword;
